$(() => {
  $(".js-hamburger").click(function() {
    hamburgerButton = $(this);

    if (hamburgerButton.hasClass("open")) {
      hamburgerButton.removeClass("open");
      hamburgerButton.addClass("close");
    }
    else {
      hamburgerButton.removeClass("close");
      hamburgerButton.addClass("open");
    }

    responsiveHamburgerMenu();
  });

  function responsiveHamburgerMenu() {
    if ($(window).width() <= 768) {
      $("#sideBar .sideMenu").stop().slideToggle();
      $("body").toggleClass("fixed");
    }
  }
});
